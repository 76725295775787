
import Vue from "vue";
import store from "store";

export default Vue.extend({
  methods: {
    async submitTwitterAuth() {
      const response = await fetch(`${process.env.VUE_APP_TWITTER_AUTH_API_ENDPOINT}/?cb=${window.location.origin}`);
      const json = await response.json();
      console.log({ json });

      store.set("twitter-token-secret", json.oauthTokenSecret);
      window.location.href = json.authenticateUrl;
    },
    backToSignIn() {
      this.$router.push({ name: "signIn" });
    },
  },
});
