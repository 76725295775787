
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import SignUpConfirmCode from "@/components/organisms/SignUpConfirmCode.vue";
import { Auth } from "aws-amplify";

export default Vue.extend({
  components: { TextField, SignUpConfirmCode },
  props: {
    propEmail: {
      type: String,
      default: "",
    },
    propMessage: {
      type: String,
      default: "",
    },
  },
  name: "signup",
  created() {
    const { propEmail, propMessage } = this;

    this.email = propEmail;
    this.message = propMessage;
  },
  data() {
    return {
      confirmCode: "",
      email: "",
      message: "",
      inputConfirmCode: false,
    };
  },
  methods: {
    async complete() {
      const { email } = this;
      this.$router.push({ name: "signIn", params: { email } });
    },
    async resend() {
      const { email } = this;
      if (!email) return;
      try {
        await Auth.resendSignUp(email);
        this.message = "メールアドレスに確認コードを送信しました。";
        this.confirmCode = "";
        this.inputConfirmCode = true;
      } catch (err) {
        if (err.code === "InvalidParameterException") {
          this.errorMessage = "ユーザーは確認済みです。ログインページに行ってログインしてください。";
        } else if (err.code === "UserNotFoundException") {
          this.errorMessage =
            "指定メールアドレスのユーザーは存在しません。会員登録ページに行って会員登録してください。";
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.errorMessage = `内部エラーが発生しました ${JSON.stringify(err)}`;
        }
      }
    },
  },
});
