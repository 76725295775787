
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import { Auth } from "aws-amplify";

export default Vue.extend({
  components: { ButtonDefault, TextField },
  props: {
    propEmail: { type: String, default: "" },
  },
  data() {
    return {
      email: this.propEmail,
      password: "",
      message: "",
    };
  },
  methods: {
    async submit() {
      const { email, password } = this;

      try {
        const res = await Auth.signIn(email, password);
        if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$router.push({ name: "newPassword" });
        } else if (res) {
          this.$router.push({ name: "tutorial" });
          // eslint-disable-next-line no-throw-literal
        } else throw "error";
      } catch (err) {
        if (err.code === "UserNotConfirmedException") {
          this.$router.push({
            name: "resendConfirmCode",
            params: {
              propEmail: email,
              propMessage: "確認コードが入力されていません。確認コードを入力ください。",
            },
          });
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.message = "ユーザー名またはパスワードが違います。";
        }
        setTimeout(() => {
          this.message = "";
        }, 3000);
      }
    },
  },
});
