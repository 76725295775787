
import Vue from "vue";
import { Auth } from "aws-amplify";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";

export default Vue.extend({
  components: { TextField, ButtonDefault },
  data() {
    return {
      username: "",
      message: "",
      submitting: false,
    };
  },
  methods: {
    async submit() {
      const { username } = this;

      try {
        this.submitting = true;
        await Auth.forgotPassword(username);
        this.$router.push({
          name: "resetPasswordSubmit",
          params: { username },
        });
      } catch (error: any) {
        this.submitting = false;
        console.error(error);
        if (error.code === "UserNotFoundException") {
          this.message = "このメールアドレスの登録ユーザーが見つかりません。今一度ご確認の上、ご入力ください。";
        } else if (error.code === "LimitExceededException") {
          this.message = "既定のリセット回数を超えました。しばらく待ってから再実施してください。";
        } else {
          this.message = "エラーが発生しました。";
        }
      }
    },
  },
});
