
import Vue from "vue";
import SignUpForm from "@/components/organisms/SignUpForm.vue";
import { checkDeprecatedBrowser } from "@/lib/checkDeprecatedBrowser";

export default Vue.extend({
  components: { SignUpForm },
  data() {
    return {};
  },
  created() {
    /** 非推奨ブラウザへの文言表示 */
    checkDeprecatedBrowser();
  },
});
