
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import { Auth } from "aws-amplify";

interface Props {
  email: string;
}

interface Data {
  confirmCode: string;
  message: string;
  errorMessage: string;
}

interface Computed {
  help: string;
}

interface Methods {
  submitConfirmCode(): Promise<void>;
  resend(): Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { TextField },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmCode: "",
      message: "",
      errorMessage: "",
    };
  },
  computed: {
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
  methods: {
    async submitConfirmCode() {
      const { confirmCode, email } = this;
      if (confirmCode.length !== 6 || !email) return;

      try {
        await Auth.confirmSignUp(email, confirmCode);

        this.$emit("complete");
      } catch (err: any) {
        if (err.code === "CodeMismatchException") {
          this.errorMessage = "確認コードが異なります";
        } else {
          this.errorMessage = JSON.stringify(err);
        }
        this.$emit("error");
      }
    },

    async resend() {
      const { email } = this;
      if (!email) return;
      try {
        await Auth.resendSignUp(email);
        this.message = "メールアドレスに確認コードを送信しました。";
        this.confirmCode = "";
      } catch (err: any) {
        if (err.code === "InvalidParameterException") {
          this.errorMessage = "ユーザーは確認済みです。ログインページに行ってログインしてください。";
        } else if (err.code === "UserNotFoundException") {
          this.errorMessage = "指定メールアドレスのユーザーは存在しません。会員ページに行って会員登録してください。";
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.errorMessage = `内部エラーが発生しました ${JSON.stringify(err)}`;
        }
      }
    },
  },
});
