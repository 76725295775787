
import Vue from "vue";
import SignInForm from "@/components/organisms/SignInForm.vue";
import { checkDeprecatedBrowser } from "@/lib/checkDeprecatedBrowser";

export default Vue.extend({
  components: {
    SignInForm,
  },
  props: {
    email: String,
  },
  created() {
    /** 非推奨ブラウザへの文言表示 */
    checkDeprecatedBrowser();
  },
});
