
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import SignUpConfirmCode from "@/components/organisms/SignUpConfirmCode.vue";
import { Auth } from "aws-amplify";

interface Data {
  email: string;
  password: string;
  passwordConfirm: string;
  message: string;
  errorMessage: string;
  inputConfirmCode: boolean;
}

interface Computed {
  help: string;
}

interface Methods {
  complete(): Promise<void>;
  submit(): Promise<void>;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: { ButtonDefault, TextField, SignUpConfirmCode },
  data() {
    return {
      email: "",
      password: "",
      passwordConfirm: "",
      message: "",
      errorMessage: "",
      inputConfirmCode: false,
    };
  },
  computed: {
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
  methods: {
    async complete() {
      const { email, password } = this;
      await Auth.signIn(email, password);
      this.$router.push({ name: "tutorial" });
    },
    async submit() {
      const { email, password, passwordConfirm } = this;
      if (!password || !email) {
        this.errorMessage = `メールアドレス、パスワードを入力してください`;
        return;
      }
      if (password !== passwordConfirm) {
        this.errorMessage = `パスワードとパスワード（確認用）が一致していません`;
        return;
      }
      // eslint-disable-next-line no-useless-escape
      if (!email.match(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/)) {
        this.errorMessage = "メールアドレスの形式が不正です";
        return;
      }

      try {
        await Auth.signUp({
          username: email,
          password,
        });
        this.message =
          // eslint-disable-next-line no-useless-concat
          "メールアドレスにメールを送信しました。" + "メールに記載されたリンクにアクセスして登録を完了してください。";
        this.errorMessage = "";
        this.inputConfirmCode = true;
      } catch (err: any) {
        if (err.code === "UsernameExistsException") {
          this.errorMessage = `メールアドレスは既に登録済みです`;
        } else if (err.code === "InvalidParameterException" || err.code === "InvalidPasswordException") {
          this.errorMessage = `パスワードは7文字以上かつ大文字・小文字を含む文字列にしてください`;
        } else if (err.code === "LimitExceededException") {
          this.errorMessage = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          console.log(err);
          this.errorMessage = `エラーが発生しました: ${err}`;
        }
      }
    },
  },
});
