
import Vue from "vue";
import TextField from "@/components/molecules/TextField.vue";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import { Auth } from "aws-amplify";
import qs from "querystring";

interface Props {
  propEmail: string;
}

interface Data {
  email: string;
  password: string;
  message: string;
}

interface Computed {
  help: string;
}

interface Methods {
  submit(): void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ButtonDefault, TextField },
  props: {
    propEmail: { type: String, default: "" },
  },
  data() {
    return {
      email: this.propEmail,
      password: "",
      message: "",
    };
  },
  computed: {
    help() {
      return process.env.VUE_APP_NOTION_HELP!;
    },
  },
  methods: {
    async submit() {
      const { email, password } = this;

      try {
        const res = await Auth.signIn(email, password);
        if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$router.push({ name: "newPassword" });
        } else if (res) {
          const p = qs.parse(window.location.search.slice(1));
          // this.$router.push(<string>p.redirect || "/");
          window.location.href = (p.redirect as string) || "/home";
        } else {
          throw new Error("error");
        }
      } catch (err: any) {
        if (err.code === "UserNotConfirmedException") {
          this.$router.push({
            name: "resendConfirmCode",
            params: {
              propEmail: email,
              propMessage: "確認コードが入力されていません。確認コードを入力ください。",
            },
          });
        } else if (err.code === "LimitExceededException") {
          this.message = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.message = "ユーザー名またはパスワードが違います。";
        }
        setTimeout(() => {
          this.message = "";
        }, 3000);
      }
    },
  },
});
