
import Vue from "vue";
import { Auth } from "aws-amplify";
import ButtonDefault from "@/components/atoms/ButtonDefault.vue";
import TextField from "@/components/molecules/TextField.vue";
import { showNotifyDialog } from "@/lib/dialog";

interface Data {
  message: string;
  newPassword: string;
  code: string;
}

interface Methods {
  submit(): Promise<void>;
}

interface Computed {
  from: string | undefined;
}

interface Props {
  username: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ButtonDefault, TextField },
  props: {
    username: String,
  },
  created() {
    if (this.from === "nolanovel") {
      document.title = "Nolaノベル パスワード再設定";
    } else if (this.from === "default") {
      document.title = "Nola関連アカウント パスワード再設定";
    }
  },
  data() {
    return {
      message: "",
      newPassword: "",
      code: "",
    };
  },
  computed: {
    from() {
      return this.$route.query.from;
    },
  },
  methods: {
    async submit() {
      const { username, code, newPassword: password } = this;

      try {
        const res = await Auth.forgotPasswordSubmit(username, code, password);
        console.log(res);

        await showNotifyDialog({
          content: this.from === "default" ? "パスワードの再設定が完了しました。ご利用のNolaサービスでログインしてください。" : "パスワードの再設定が完了しました。"
        });
        if (this.from === "default") {
          window.location.href="https://indent.co.jp/#product";
        } else if (this.from === "nolanovel") {
          window.location.href=`${process.env.VUE_APP_NOLANOVEL_WEB}/auth`;
        } else {
          this.$router.push({ name: "signIn" });
        }
      } catch (error) {
        if (error.code === "CodeMismatchException") {
          this.message = `確認コードが正しくありません`;
        } else if (error.code === "UsernameExistsException") {
          this.message = `メールアドレスは既に登録済みです`;
        } else if (error.code === "InvalidParameterException" || error.code === "InvalidPasswordException") {
          this.message = `パスワードは7文字以上かつ大文字・小文字を含む文字列にしてください`;
        } else if (error.code === 'LimitExceededException') {
          this.message = "送信回数制限に達しました。しばらくしてから再度やり直してください。";
        } else {
          this.message = `エラーが発生しました: ${error.message}`;
        }
      }
    }
  }
});
